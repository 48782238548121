@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Slab:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
/* For WebKit browsers (Chrome, Safari) */
/* Styles for the TreeNode */
/* Styles for the TreeNode */
/* Styles for the TreeNode */

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  font-family: Roboto Slab;
}
body{
  background: #081923e3;
}
.tree-node {
    position: relative;
    margin-bottom: 40px;
  }
  
  .tree-node img {
    border-radius: 50%;
    border: 2px solid #ddd;
  }
  
  .tree-node .line {
    position: absolute;
    top: 10px;
    left: 50%;
    height: 100%;
    width: 1px;
    background-color: #ccc;
  }
  
  .tree-node .children {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .tree-node .children .tree-node {
    margin: 0 20px;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  ::-webkit-scrollbar-track {
    background: #111827; 
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #353535; 
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  



